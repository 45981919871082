export default function getCategories() {
  return {
    townhouse: {
      name: 'Дом, коттедж, таунхаус',
      image: 'images/solutions/sub-categories/water.png',
      to: '/resheniya-dlya-domov',
      inner: {
        water: {
          name: 'Водоснабжение',
          image: 'images/solutions/sub-categories/water.png',
          to: '/resheniya-dlya-domov/vodosnabzhenie/',
          inner: {
            design: {
              name: 'Проектирование',
              image: 'images/solutions/detail/water/desing.jpg',
              to: '/resheniya-dlya-domov/vodosnabzhenie/proektirovanie/',
            },
            montage: {
              name: 'Монтаж',
              image: 'images/solutions/detail/water/montage.jpg',
              to: '/resheniya-dlya-domov/vodosnabzhenie/montazh/',
            },
            repair: {
              name: 'Ремонт',
              image: 'images/solutions/detail/water/repaire.jpg',
              to: '/resheniya-dlya-domov/vodosnabzhenie/remont/',
            },
            renovation: {
              name: 'Ремонт под ключ',
              image: 'images/solutions/detail/water/renovation.jpg',
              to: '/resheniya-dlya-domov/vodosnabzhenie/pod-klyuch/',
            },
          },
        },
        sewerage: {
          name: 'Канализация',
          image: 'images/solutions/sub-categories/sewerage.png',
          to: '/resheniya-dlya-domov/kanalizacziya',
          inner: {
            montage: {
              name: 'Монтаж',
              image: 'images/solutions/detail/sewerage/montage.jpg',
              to: '/resheniya-dlya-domov/kanalizacziya/montazh/',
            },
            repair: {
              name: 'Ремонт',
              image: 'images/solutions/detail/sewerage/repaire.jpg',
              to: '/resheniya-dlya-domov/kanalizacziya/remont/',
            },
          },
        },
        heating: {
          name: 'Системы отопления',
          image: 'images/solutions/sub-categories/heating.png',
          to: '/resheniya-dlya-domov/sistema-otopleniya/',
          inner: {
            design: {
              name: 'Проектирование',
              image: 'images/solutions/detail/heating/desing.jpg',
              to: '/resheniya-dlya-domov/sistema-otopleniya/proektirovanie/',
            },
            montage: {
              name: 'Монтаж',
              image: 'images/solutions/detail/heating/montage.jpg',
              to: '/resheniya-dlya-domov/sistema-otopleniya/montazh/',
            },
            renovation: {
              name: 'Под ключ',
              image: 'images/solutions/detail/heating/renovation.png',
              to: '/resheniya-dlya-domov/sistema-otopleniya/pod-klyuch/',
            },
            service: {
              name: 'Обслуживание систем отопления',
              image: 'images/solutions/detail/heating/service.jpg',
              to: '/resheniya-dlya-domov/sistema-otopleniya/obsluzhivanie/',
            },
            repair: {
              name: 'Ремонт',
              image: 'images/solutions/detail/heating/repaire.jpg',
              to: '/resheniya-dlya-domov/sistema-otopleniya/remont/',
            },
          },
        },
        heating_open: {
          name: 'Обогрев открытых площадок',
          image: 'images/solutions/sub-categories/heating-open.png',
          to: '/resheniya-dlya-domov/obogrev-otkrytykh-ploshhadok/',
        },
        microclimate: {
          name: 'Системы управления микроклиматом',
          image: 'images/solutions/sub-categories/microclimate.png',
          to: '/resheniya-dlya-domov/sistema-upravleniya-mikroklimatom/',
        },
        leak: {
          name: 'Защита от протечек',
          image: 'images/solutions/sub-categories/leak-protection.png',
          to: '/resheniya-dlya-domov/zashhita-ot-protechek/',
        },
        warm_floor: {
          name: 'Теплые полы',
          image: 'images/solutions/sub-categories/warm-floor.png',
          to: '/resheniya-dlya-domov/teplye-poly/',
          inner: {
            water: {
              name: 'Монтаж водяного пола',
              image: 'images/solutions/detail/warm-floor/water-montage.jpg',
              to: '/resheniya-dlya-domov/teplye-poly/montazh-vodyanogo-pola/',
            },
            electro: {
              name: 'Монтаж электрического пола',
              image: 'images/solutions/detail/warm-floor/electro-montage.jpg',
              to: '/resheniya-dlya-domov/teplye-poly/montazh-elektricheskogo-pola/',
            },
          },
        },
        сomplete: {
          name: 'Комплексные решения для домов',
          image: 'images/solutions/sub-categories/сomplete.png',
          to: '/resheniya-dlya-domov/kompleksnye-resheniya/',
        },
      },
    },
  }
}
