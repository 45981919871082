/* eslint-disable camelcase */
import axios from 'axios'
import moment from 'moment'

import { getConfig } from '@/configs/app.configs'
import { DEFAULT_ORDER_PAGE_SIZE } from '@/constants/order.constants'
import { useUserStore } from '@/store/modules/user.store'
import { getAccessToken } from '@/utilities/access-token-utility'

export class OrderProvider {
  static async getInstallers(form, pagination, type = 'recommended') {
    const isClient = useUserStore().isClient
    const specializations = form.specializations.map((specialization) => specialization.id)
    const additionalServices = form.additionalWorks.map((additionalService) => additionalService.id)
    let object_new_type
    let country
    let region
    if (form.objectVariant === 'new') {
      if (form.newObject.objectTypes) {
        object_new_type = form.newObject.objectTypes.id
      }
      if (form.newObject.location?.data?.country) {
        country = form.newObject.location.data.country
      }
      if (form.newObject.location?.data?.region_with_type) {
        region = form.newObject.location.data.region_with_type
      }
    }

    let start_date
    if (form.date?.start) {
      start_date = moment(form.date.start).format('DD.MM.yyyy')
    }

    let end_date
    if (form.date?.end) {
      end_date = moment(form.date.end).format('DD.MM.yyyy')
    }

    let page
    if (type === 'recommended') {
      page = pagination.currentPage
    }

    let pageSize
    if (type === 'recommended') {
      pageSize = DEFAULT_ORDER_PAGE_SIZE
    }

    let object_exist
    if (isClient && form.existObject?.id) {
      object_exist = form.existObject.id
    }

    let selected_installers
    if (type === 'available') {
      const manuallySelectedInstallers = form.selectedInstallers.filter((item) => item.manuallySelected)
      selected_installers = manuallySelectedInstallers.map((item) => item.performerId)
    }

    const query = {
      specializations,
      additionalServices,
      object_new_type,
      country,
      region,
      start_date,
      end_date,
      object_exist,
      page,
      pageSize,
      selected_installers,
    }

    let url
    if (type === 'recommended') {
      if (isClient) {
        url = '/api-lkz/auth/quiz/montage_users'
      } else {
        url = '/api-lkz/quiz/montage_users'
      }
    }
    if (type === 'selected') {
      if (isClient) {
        url = '/api-lkz/auth/quiz/montage_users_right'
      } else {
        url = '/api-lkz/quiz/montage_users_right'
      }
    }
    if (type === 'available') {
      if (isClient) {
        url = '/api-lkz/auth/quiz/check_montage_users'
      } else {
        url = '/api-lkz/quiz/check_montage_users'
      }
    }

    const res = await axios.get(url, {
      baseURL: getConfig('backUrlLkz'),
      params: query,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    return res.data
  }

  static async getDefaultSelectedInstallers(form) {
    const isClient = useUserStore().isClient
    const specializations = form.specializations.map((specialization) => specialization.id)
    const additionalServices = form.additionalWorks.map((additionalService) => additionalService.id)
    let object_new_type
    let country
    let region
    if (form.objectVariant === 'new') {
      if (form.newObject.objectTypes) {
        object_new_type = form.newObject.objectTypes.id
      }
      if (form.newObject.location?.data?.country) {
        country = form.newObject.location.data.country
      }
      if (form.newObject.location?.data?.region_with_type) {
        region = form.newObject.location.data.region_with_type
      }
    }

    let start_date
    if (form.date.start) {
      start_date = moment(form.date.start).format('DD.MM.yyyy')
    }

    let end_date
    if (form.date.end) {
      end_date = moment(form.date.end).format('DD.MM.yyyy')
    }

    let object_exist
    if (isClient && form.existObject?.id) {
      object_exist = form.existObject.id
    }

    const query = {
      specializations,
      additionalServices,
      object_new_type,
      country,
      region,
      start_date,
      end_date,
      object_exist,
    }
    let url = '/api-lkz/quiz/montage_users_right'
    if (isClient) {
      url = '/api-lkz/auth/quiz/montage_users_right'
    }

    const res = await axios.get(url, {
      baseURL: getConfig('backUrlLkz'),
      params: query,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    return res.data
  }

  static async getInstallersInfo(ids) {
    const isClient = useUserStore().isClient

    let url = '/api-lkz/quiz/montage_users_info'

    if (isClient) {
      url = '/api-lkz/auth/quiz/montage_users_info'
    }

    const res = await axios.get(url, {
      baseURL: getConfig('backUrlLkz'),
      params: { selected_installers: ids },
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    return res.data
  }

  static async getSpecializations(page) {
    const url = '/api-lkz/quiz/specializations'
    const res = await axios.get(url, {
      baseURL: getConfig('backUrlLkz'),
      params: { page },
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    return res.data
  }

  static async getObjectTypes(page) {
    const url = '/api-lkz/quiz/objects/types'
    const res = await axios.get(url, {
      baseURL: getConfig('backUrlLkz'),
      params: { page },
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    return res.data
  }

  static async getExistObjects(page) {
    const url = '/api-lkz/auth/quiz/objects/list/my'
    const res = await axios.get(url, {
      baseURL: getConfig('backUrlLkz'),
      params: { page },
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    return res.data
  }

  static async getAdditionalWorks(page) {
    const url = '/api-lkz/quiz/additional_works'
    const res = await axios.get(url, {
      baseURL: getConfig('backUrlLkz'),
      params: { page },
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    return res.data
  }

  static async getNameExamples(form) {
    let city
    if (form.objectVariant === 'new' && form.newObject.location.data?.city) {
      city = form.newObject.location.data.city
    }

    let specializations
    if (form.specializations.length) {
      specializations = form.specializations.map((specialization) => specialization.id)
    }
    const url = '/api-lkz/quiz/example_names'
    const res = await axios.post(
      url,
      {
        city,
        specializations,
      },
      {
        baseURL: getConfig('backUrlLkz'),
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
    return res.data
  }

  static async save(form, type) {
    const isClient = useUserStore().isClient
    const specializations = form.specializations.map((specialization) => specialization.id)

    const additional_services = form.additionalWorks.map((additionalService) => additionalService.id)

    let new_object
    if (form.objectVariant === 'new') {
      new_object = {
        location: {
          city: form.newObject.location.data.city,
          settlement: form.newObject.location.data.settlement,
          country: form.newObject.location.data.country,
          flat: form.newObject.location.data.flat,
          flat_type: form.newObject.location.data.flat_type,
          house: form.newObject.location.data.house,
          house_type: form.newObject.location.data.house_type,
          region: form.newObject.location.data.region_with_type,
          street: form.newObject.location.data.street,
          street_type: form.newObject.location.data.street_type,
          string: form.newObject.location.value,
        },
        object_area: form.newObject.objectArea,
        object_type: form.newObject.objectTypes.id,
      }
    }

    let object_exist

    if (form.objectVariant === 'exist' && isClient) {
      object_exist = form.existObject.id
    }

    let files
    if (form.files.length) {
      files = form.files
    }

    let links
    if (form.links.length) {
      links = form.links.map((item) => {
        return {
          name: item.name,
          url: item.url,
        }
      })
    }

    let wishes
    if (form.wishes) {
      wishes = form.wishes
    }

    let start_date
    if (form.date?.start) {
      start_date = form.date.start
    }

    let end_date
    if (form.date?.end) {
      end_date = form.date.end
    }

    let selected_installers
    if (form.selectedInstallers.length) {
      selected_installers = form.selectedInstallers.map((selectedInstaller) => selectedInstaller.performerId)
    }

    let name
    if (form.name) {
      name = form.name
    }

    const body = {
      specializations,
      additional_services,
      new_object,
      object_exist,
      files,
      links,
      wishes,
      start_date,
      end_date,
      selected_installers,
      name,
      type,
    }

    const url = '/api-lkz/auth/quiz/services_request'

    return await axios.post(url, body, {
      baseURL: getConfig('backUrlLkz'),
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  }
}
