<template>
  <nav class="footer-top__nav">
    <ul class="footer-top__actions-list">
      <li v-for="(page, index) in firstList" :key="index" class="footer-top__actions-item">
        <NuxtLink :to="page.to" class="footer-top__actions-link" @click.native="clickNavbarLink(page.name)">
          {{ page.title }}
        </NuxtLink>
      </li>
    </ul>
    <ul class="footer-top__actions-list">
      <li v-for="(page, index) in secondList" :key="index" class="footer-top__actions-item">
        <NuxtLink :to="page.to" class="footer-top__actions-link" @click.native="clickNavbarLink(page.name)">
          {{ page.title }}
        </NuxtLink>
      </li>
      <li class="footer-top__actions-item">
        <a class="footer-top__actions-link" :href="getConfig('frontUrl') + '/blog/'" target="_blank"> Блог </a>
      </li>
    </ul>
  </nav>
</template>

<script>
// import { countHowItWorks } from "@/utils/counters-api";
import { getConfig } from '@/configs/app.configs'
import { FOREIGN_LANDINGS_COUNTRIES } from '@/constants/foreign-landings/foreign-landings.constants'

export default {
  name: 'FooterNavbar',
  computed: {
    firstList() {
      return this.pages.slice(0, Math.ceil(this.pages.length / 2))
    },
    secondList() {
      return this.pages.slice(Math.ceil(this.pages.length / 2))
    },
    country() {
      return this.$store.state.pages.foreignLandings.country
    },
    isForeignLanding() {
      return Boolean(this.country)
    },
    pages() {
      return [
        {
          name: 'search',
          title: 'Найти исполнителя',
          to: this.isForeignLanding
            ? {
                name: 'search',
                query: {
                  country: FOREIGN_LANDINGS_COUNTRIES[this.country].fullTitle,
                },
              }
            : '/search',
        },
        {
          name: 'order',
          title: 'Создать заказ',
          to: '/order',
        },
        {
          name: 'ask',
          title: 'Написать нам',
          to: '/ask',
        },
        {
          name: 'about',
          title: 'О проекте',
          to: '/about',
        },
        {
          name: 'contacts',
          title: 'Контакты',
          to: '/contacts',
        },
      ]
    },
  },
  methods: {
    getConfig,
    clickNavbarLink(name) {
      switch (name) {
        case 'search':
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickGoToSearch',
          })
          // this.$yandexMetrika.reachGoal('ClickGoToSearch')
          break
        case 'order':
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickGoToOrder',
          })
          // this.$yandexMetrika.reachGoal('ClickGoToOrder')
          break
        case 'ask':
          // countHowItWorks();
          // this.$gtm.push({
          //   event: 'reachGoal',
          //   target: 'Goals',
          //   action: 'ClickGoToHow',
          // })
          // this.$yandexMetrika.reachGoal('ClickGoToHow')
          break
        case 'contacts':
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickGoToContacts',
          })
          // this.$yandexMetrika.reachGoal('ClickGoToContacts')
          break
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
