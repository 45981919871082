// import {
//   getCompetences,
//   getSpecializations
// } from "@/utils/search-api/search-api";

const state = () => ({
  specializations: [],
  competences: [],
})

const actions = {
  async fetchSpecializations({ commit, rootState }) {
    const res = await this.$axios.get(`/custapi/allrest/search/spec`)
    const specializations = await res.data
    commit('fetchSpecializations', {
      filter: rootState.pages.search.filter,
      // filterModule: rootState.search.filterModule,
      specializations,
    })
  },

  async fetchCompetences({ commit, rootState }) {
    const res = await this.$axios.get(`/custapi/allrest/search/competences`)
    const competences = await res.data
    commit('fetchCompetences', {
      filter: rootState.pages.search.filter,
      competences,
    })
  },
}

const mutations = {
  fetchSpecializations(state, { filter, specializations }) {
    state.specializations = specializations
    const specializationsObject = {}
    // заполняем specializations в filterFields из модуля filterModule
    for (const spec of specializations) {
      specializationsObject[spec.valueId] = false
      // if (!filterModule.filterFields.specializations[spec.valueId]) {
      //   specializationsObject[spec.valueId] = false;
      // } else {
      //   specializationsObject[spec.valueId] = true;
      // }
    }
    filter.filterFields.specializations = specializationsObject
  },

  fetchCompetences(state, { filter, competences }) {
    state.competences = competences
    // заполняем competences в filterFields из модуля filterModule
    const competencesObject = {}
    for (const comp of competences) {
      competencesObject[comp.valueId] = false
      // if (!filterModule.filterFields.competences[comp.valueId]) {
      //   competencesObject[comp.valueId] = false;
      // } else {
      //   competencesObject[comp.valueId] = true;
      // }
    }
    filter.filterFields.competences = competencesObject
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
