<template>
  <div class="error__wrapper">
    <div class="container error">
      <div class="error__info">
        <p class="ui-h4 error__type">Ошибка {{ error.statusCode }}</p>
        <p v-if="error.message" class="error__text">
          {{ error.message }}
        </p>
        <p v-if="!error.message" class="error__text">
          Ой, что-то сломалось или так было задумано. Но мы же профессионалы :)
        </p>
        <div class="error__btn-wrapper">
          <NuxtLink to="/" class="ui-link -red">На главную</NuxtLink>
          <NuxtLink to="/search" class="ui-link -red error__btn"> Найти исполнителя </NuxtLink>
          <NuxtLink to="/ask" class="ui-link -red error__btn"> Создать заказ </NuxtLink>
        </div>
      </div>
      <div class="error__img">
        <img src="@/assets/images/Error/error-img.svg" alt="Картинка 'Ой, что-то сломалось'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'error',
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';
.error {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }

  &__wrapper {
    padding: 64px 0 112px 0;
    box-sizing: border-box;
    @media (max-width: 1439px) {
      padding: 32px 0 52px 0;
    }
    @media (max-width: 1023px) {
      padding: 64px 0 50px 0;
    }
    @media (max-width: 767px) {
      padding: 32px 0 40px 0;
    }
  }

  &__info {
    max-width: 486px;
    width: 100%;
    margin: 0 126px 0 0;
    padding: 50px 0 0 0;
    box-sizing: border-box;
    @media (max-width: 1439px) {
      margin: 0 20px 0 0;
    }
    @media (max-width: 1023px) {
      max-width: 348px;
      padding: 0;
    }
    @media (max-width: 767px) {
      text-align: center;
      max-width: 288px;
      margin: 0;
    }
  }

  &__type {
    color: $black-60;
    margin: 0 0 24px 0;
    @media (max-width: 767px) {
      margin: 0 0 16px 0;
    }
  }

  &__text {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    margin: 0 0 24px 0;
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__btn-wrapper {
    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 767px) {
      align-items: center;
    }

    & a {
      padding: 10px 0;
      box-sizing: border-box;
      color: $active-red;
      text-decoration: none;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        color: $active-red !important;
        text-decoration: underline;
      }
    }
  }

  &__btn {
    margin: 0 0 0 24px;
    @media (max-width: 1023px) {
      margin: 8px 0 0 0;
    }
  }

  &__img {
    width: 486px;
    @media (max-width: 1439px) {
      width: 461px;
    }
    @media (max-width: 1023px) {
      width: 354px;
    }
    @media (max-width: 767px) {
      order: -1;
      width: 227px;
      margin: 0 0 40px 0;
    }

    & img {
      width: 100%;
    }
  }
}
</style>
