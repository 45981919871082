// import { convertDateToStringDMY } from "@/utils/dates-api";

import { convertDateToStringDMY } from '@/utilities/date/methods/convertDateToStringDMY'

export default function getSearchParams(state) {
  const {
    name,
    experienceYears,
    reviewsCount,
    performerTypes,
    specializations,
    competences,
    professionalStatuses,
    datesRange,
  } = state.filter.filterFields
  const { page, sortMode } = state.sort

  const searchParams = {
    name,
    numWorks: 0,
    experienceYears: Math.floor(experienceYears),
    reviewsCount: Math.floor(reviewsCount),
    performerTypes,
    professionalStatuses,
    avgEstimate: 0,
    avgNoEstimate: null,
    objectType: 0,
    location: null,
    specializations: null,
    competences: null,
    startDate: null,
    endDate: null,
    ...sortMode,
    page,
  }
  // форматируем специализации
  const specializationsArray = []
  for (const specId in specializations) {
    if (specializations[specId]) specializationsArray.push(Number(specId))
  }
  searchParams.specializations = specializationsArray
  // форматируем компетенции
  const competencesArray = []
  for (const compId in competences) {
    if (competences[compId]) competencesArray.push(Number(compId))
  }
  searchParams.competences = competencesArray
  // заполняем даты начала и конца
  if (datesRange) {
    searchParams.startDate = convertDateToStringDMY(datesRange[0].getDate())
    searchParams.endDate = convertDateToStringDMY(datesRange[1].getDate())
  }
  // заполняем objType
  if (state.object.object) {
    searchParams.objectType = state.object.object.valueId
  } else {
    searchParams.objectType = null
  }
  // заполняем данные по локации
  const { data: daDataObject } = state.location.location
  searchParams.country = daDataObject?.country
  searchParams.region = daDataObject?.region_with_type
  searchParams.city = daDataObject?.city_with_type
  return searchParams
}
