export const SOLUTIONS_SUBMENU = [
  {
    name: 'sl-dzr-latun',
    title: 'DZR латунь',
    to: '/dzr-latun',
  },
  {
    name: 'sl-quality-control',
    title: 'Контроль качества',
    to: '/quality-control',
  },
  {
    name: 'sl-r-bonus',
    title: 'R.Bonus',
    to: '/r-bonus',
  },
  {
    name: 'sl-education',
    title: 'Обучение и клуб монтажников',
    to: '/education',
  },
  {
    name: 'sl-r-garant',
    title: 'R.Garant',
    to: '/r-garant',
  },
]
