/* eslint-disable */
export default ({ app }) => {
  if (process.browser) {
    /* пока убираю, т.к. сделала интеграцию виджета ссылками, он сам определяет роли
    но делает это плохо
    но для публички это не особо критично

    let tmpRole = 'guest'

    const userData = app.pinia.state.value.user.data

    if (userData) {
      switch (userData.userDomain) {
        case 'MONTAGE':
          tmpRole = 'montage'
          break
        case 'CLIENT':
          tmpRole = 'customer'
          break
        case 'WORKER':
          tmpRole = 'employee'
          break
      }
    }
    */
    // выбор данных по урлу
    const isSupport = window.location.href.includes('support')

    // сбросить статус, если есть, используется только в лкм
    const tmpStatus = JSON.parse(sessionStorage.getItem('CUSTOM_EVENT_DATASET'))
    if (tmpStatus?.status) {
      setWidgetStatus('')
    }

    const chatId = isSupport ? CHAT_SETTINGS.mobile.id : CHAT_SETTINGS.base.id
    const scriptSrc = isSupport ? CHAT_SETTINGS.mobile.src : CHAT_SETTINGS.base.src

    // if (isSupport) return

    addJsFile(chatId, scriptSrc)
  }
}

export const CHAT_SETTINGS = {
  base: {
    id: 'chat-base',
    divId: 'metabot-8bf6efdca7e0db289244c8b5eb01f577',
    src: 'https://user43148.clients-cdnnow.ru/js/chatwidget/pro.rhsolutions/rhsolutions-script.js',
  },
  mobile: {
    id: 'chat-mobile',
    divId: 'metabot-7c2aef895198fa94de78737961d06da1',
    src: 'https://user43148.clients-cdnnow.ru/js/chatwidget/pro.rhsolutions.globus.main/rh-mobile-site-script.js',
  }
}

export function removeWidgetJsFile(id, divId) {
  const script = document.getElementById(id)
  const div = document.getElementById(divId)
  if (script) {
    script.parentNode.removeChild(script)
  }
  if (div) {
    div.parentNode.removeChild(div)
    const divNew = document.createElement('div')
    divNew.setAttribute('id', divId)
    document.getElementsByTagName('body')[0].appendChild(divNew)
  }
}

export function addJsFile(id, src) {
  const script = document.getElementById(id)

  if (script && script?.src === src) return

  const body = document.body
  const newScript = document.createElement('script')
  newScript.type = 'text/javascript'
  newScript.src = src
  newScript.id = id

  body.appendChild(newScript)
}

export function setWidgetStatus(code) {
  sessionStorage.setItem(
    'CUSTOM_EVENT_DATASET',
    JSON.stringify({
      status: code,
    }),
  )
}
