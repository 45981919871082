import Keycloak from 'keycloak-js'

import { getConfig } from '@/configs/app.configs'

export default async ({ app }, inject) => {
  const keycloak = new Keycloak({
    realm: 'rehau',
    url: getConfig('keycloakUrl'),
    clientId: 'lkm',
  })
  inject('keycloak', keycloak)

  keycloak.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  })
}
