import { isEmpty } from 'lodash'

import { computeSeoTemplate } from '@/utilities/search/methods/computeSeoTemplate'
import { computeAddressSeo } from '@/utilities/search/urlParams/addressUrlParam'
import { computeExperienceSeo } from '@/utilities/search/urlParams/experienceUrlParam'
import { computeFeedbacksSeo } from '@/utilities/search/urlParams/feedbacksUrlParam'
import { computeInstallerTypeSeo } from '@/utilities/search/urlParams/installerTypeUrlParam'
import { computeObjectSeo } from '@/utilities/search/urlParams/objectUrlParam'
import { computeProfStatusSeo } from '@/utilities/search/urlParams/profStatusUrlParam'
import { computeSpecializationSeo } from '@/utilities/search/urlParams/specializationUrlParam'

export function computeSeoParams(searchStore) {
  const { location, object, filter, specializations, seo } = searchStore
  const { seoParamsForTemplate } = seo
  const preparedStringPartsForTemplate = []
  const addressSeo = computeAddressSeo(seoParamsForTemplate, location)
  if (addressSeo) preparedStringPartsForTemplate.push(addressSeo)

  const objectSeo = computeObjectSeo(seoParamsForTemplate, object)
  if (objectSeo) preparedStringPartsForTemplate.push(objectSeo)

  const experienceSeo = computeExperienceSeo(seoParamsForTemplate, filter)
  if (experienceSeo) preparedStringPartsForTemplate.push(experienceSeo)

  const feedbacksSeo = computeFeedbacksSeo(seoParamsForTemplate, filter)
  if (feedbacksSeo) preparedStringPartsForTemplate.push(feedbacksSeo)

  const installerTypeSeo = computeInstallerTypeSeo(seoParamsForTemplate, filter)
  if (installerTypeSeo) preparedStringPartsForTemplate.push(installerTypeSeo)

  const specializationSeo = computeSpecializationSeo(seoParamsForTemplate, filter, specializations)
  if (specializationSeo) preparedStringPartsForTemplate.push(specializationSeo)

  const profStatusSeo = computeProfStatusSeo(seoParamsForTemplate, filter)
  if (profStatusSeo) preparedStringPartsForTemplate.push(profStatusSeo)

  if (!isEmpty(preparedStringPartsForTemplate)) {
    return computeSeoTemplate(seo.seoTemplate, seoParamsForTemplate, preparedStringPartsForTemplate)
  }
}
