const name = 'РЕХАУ'
const nameCapitalize = 'Рeхау'
const subName = 'Про'

const httpsPrefix = 'https://'

const main = 'rhsolutions.ru'
const mainWithHttpsPrefix = `${httpsPrefix}${main}`

const shop = 'voda.shop.rhsolutions.ru'
const shopWithHttpsPrefix = `${httpsPrefix}${shop}`
const vkWithHttpsPrefix = 'https://vk.com/pro.rhsolution'
const tgWithHttpsPrefix = 'https://t.me/prorhsolutions'

const online = 'rehau-online.ru'
const onlineWithHttpsPrefix = `https://maps.rhsolutions.ru/partners/filters`

const email = 'pro@support.rhsolutions.ru'
const emailWithHttpsPrefix = `mailto:${email}`
const mainProWithHttpsPrefix = `${httpsPrefix}pro.${main}`

const phone = {
  label: '8 (800) 555-33-55',
  link: '88005553355',
}

const links = {
  main,
  mainWithHttpsPrefix,
  shop,
  shopWithHttpsPrefix,
  vkWithHttpsPrefix,
  tgWithHttpsPrefix,
  online,
  onlineWithHttpsPrefix,
  email,
  emailWithHttpsPrefix,
  mainProWithHttpsPrefix,
}

export const COMPANY = {
  name,
  subName,
  fullName: `${name}.${subName}`,
  mobileAppName: `${nameCapitalize}.${subName} Качество`,
  links,
  phone,
}
