import { URL_PARAMS } from '@/constants/search/url-param-constants'
import checkIsOnlyOneParam from '@/utilities/search/methods/checkIsOnlyOneParam'
import { getSeoValues, getSmartFilterSeoValueByValueId } from '@/utilities/search/methods/getSeoParamValues'
import { getTypeObjectByUrlParamValue } from '@/utilities/search/methods/getTypeObject'
import { getUrlParamValueByValueId } from '@/utilities/search/methods/getUrlParamValue'

export function encodeSpecializationParam(filterModule, specializationsModule) {
  const filterFieldSpecializations = filterModule.filterFields.specializations
  const filterFieldCompetences = filterModule.filterFields.competences
  const isOnlyOneParam = checkIsOnlyOneParam(filterFieldSpecializations, filterFieldCompetences)
  if (isOnlyOneParam) {
    for (const specId in filterFieldSpecializations) {
      if (filterFieldSpecializations[specId]) {
        const typesArraySpecializations = specializationsModule.specializations
        const paramValue = getUrlParamValueByValueId(specId, typesArraySpecializations)
        return { [URL_PARAMS.specialization]: paramValue }
      }
    }
    for (const compId in filterFieldCompetences) {
      if (filterFieldCompetences[compId]) {
        const typesArrayCompetences = specializationsModule.competences
        const paramValue = getUrlParamValueByValueId(compId, typesArrayCompetences)
        return { [URL_PARAMS.specialization]: paramValue }
      }
    }
  }
}
export function decodeSpecializationParam(urlParamsFromQuery, specializationsModule, dispatch) {
  const specializationParam = urlParamsFromQuery[URL_PARAMS.specialization]
  if (specializationParam) {
    const specializationTypes = specializationsModule.specializations
    const competenceTypes = specializationsModule.competences
    const allSpecializationTypes = [...specializationTypes, ...competenceTypes]
    const typeObject = getTypeObjectByUrlParamValue(specializationParam, allSpecializationTypes)
    if (typeObject?.dictCode === 'SPECIALIZATION') {
      dispatch('pages/search/filter/updateFilterSpecializationField', typeObject.valueId, { root: true })
    }
    if (typeObject?.dictCode === 'COMPETENCE') {
      dispatch('pages/search/filter/updateFilterCompetenceField', typeObject.valueId, { root: true })
    }
  }
}
export function computeSpecializationSeo(smartFilterSeoParams, filterModule) {
  const filterFieldSpecializations = filterModule.filterFields.specializations
  const filterFieldCompetences = filterModule.filterFields.competences
  const isOnlyOneParam = checkIsOnlyOneParam(filterFieldSpecializations, filterFieldCompetences)
  if (isOnlyOneParam) {
    const variable = URL_PARAMS.specialization.toUpperCase()
    for (const specId in filterFieldSpecializations) {
      if (filterFieldSpecializations[specId]) {
        const seoParamValues = getSeoValues('specialization', smartFilterSeoParams)
        const specializationSeoValue = getSmartFilterSeoValueByValueId(Number(specId), seoParamValues)
        const { h1, title, description } = specializationSeoValue
        return { h1, title, description, variable }
      }
    }
    for (const compId in filterFieldCompetences) {
      if (filterFieldCompetences[compId]) {
        const seoParamValues = getSeoValues('competences', smartFilterSeoParams)
        const competencesSeoValue = getSmartFilterSeoValueByValueId(Number(compId), seoParamValues)
        const { h1, title, description } = competencesSeoValue
        return { h1, title, description, variable }
      }
    }
  }
}
