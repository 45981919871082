<template>
  <div>
    <header class="header">
      <div class="container">
        <div class="header__row">
          <div class="header__col -logo">
            <HeaderLogo />
          </div>
          <div class="header__col -nav">
            <nav class="header__nav">
              <HeaderNavbar />
              <HeaderUser />
            </nav>
          </div>
          <div class="header__col -burger">
            <button class="burger" @click="clickBurger">
              <span class="burger__line"></span>
              <span class="burger__line"></span>
              <span class="burger__line"></span>
            </button>
          </div>
        </div>
      </div>
      <HeaderMobileMenu />
      <!--      <Ticker />-->
    </header>
    <DefBreadcrumbs />
  </div>
</template>

<script>
import HeaderUser from '@/components/Header/HeaderUser/HeaderUser'
import DefBreadcrumbs from '@/components/ui/Breadcrumbs/DefBreadcrumbs.vue'
import { useUserStore } from '@/store/modules/user.store'
import HeaderLogo from '~/components/Header/HeaderLogo'
import HeaderMobileMenu from '~/components/Header/HeaderMobileMenu'
import HeaderNavbar from '~/components/Header/HeaderNavbar'
// import Ticker from '@/components/Header/Ticker.vue'

export default {
  components: {
    DefBreadcrumbs,
    // Ticker,
    HeaderMobileMenu,
    HeaderUser,
    HeaderNavbar,
    HeaderLogo,
  },
  setup() {
    const userStore = useUserStore()
    userStore.fetchData()
  },
  computed: {},
  methods: {
    clickBurger(evt) {
      const burger = evt.target.closest('.burger')
      const mobileMenu = document.querySelector('.mobile-menu')
      if (burger.classList.contains('js-open')) {
        burger.classList.remove('js-open')
        mobileMenu.classList.remove('js-open')
      } else {
        burger.classList.add('js-open')
        mobileMenu.classList.add('js-open')
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/header';
</style>
