<template>
  <div v-if="showBreadcrumbs" class="breadcrumbs container">
    <client-only>
      <swiper class="breadcrumbs__body" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in breadcrumbs" :key="index" class="breadcrumbs__item">
          <NuxtLink v-if="index !== breadcrumbs.length - 1" :to="item.to">{{ item.name }}</NuxtLink>
          <span v-else>{{ item.name }}</span>
          <svg
            v-if="index !== breadcrumbs.length - 1"
            width="6"
            height="9"
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11767 4.5001L0.548828 0.894239L1.37879 0.0556641L5.77759 4.5001L1.37879 8.94455L0.548828 8.10598L4.11767 4.5001Z"
              fill="#818181"
            />
          </svg>
        </swiper-slide>
      </swiper>
    </client-only>
  </div>
</template>

<script>
import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api'

import { PROJECT_PATHS } from '@/constants/breadcrumbs/breadcrumbs.constants'

export default defineComponent({
  name: 'DefBreadcrumbs',
  props: {},
  setup() {
    const swiperOptions = {
      slidesPerView: 'auto',
      spaceBetween: 8,
      allowTouchMove: false,
      breakpoints: {
        768: {
          slidesPerView: 'auto',
          spaceBetween: 8,
          allowTouchMove: true,
        },
      },
    }

    const route = useRoute()

    const showBreadcrumbs = computed(() => {
      const isLanding = route.value.fullPath.includes('landings')
      const isQr = route.value.fullPath.includes('check-qr')

      return route.value.fullPath !== '/' && !isLanding && !isQr
    })

    const breadcrumbs = computed(() => {
      let dynamicPaths = []
      // избавляемся от query-параметров и разбиваем строку по /
      const pathArr = route.value.fullPath.replace(/\?.*/, '').split('/')

      if (route.value.fullPath.includes('photo')) {
        dynamicPaths = [
          {
            name: 'Поиск',
            to: '/search',
          },
          {
            name: 'Профиль',
            to: `/profile/${pathArr[2]}`,
          },
          {
            name: `Альбом №${pathArr[3]}`,
            to: '',
          },
        ]
      } else {
        // переменная для наращивания вложенности ссылок вида /page/subpage
        let stringTo = ''

        pathArr.forEach((el) => {
          const newBreadcrumb = PROJECT_PATHS.find((path) => path.path === el)

          if (dynamicPaths.includes(newBreadcrumb) || !newBreadcrumb) return

          stringTo += '/' + el

          dynamicPaths.push({ ...newBreadcrumb, to: stringTo })
        })
      }

      return [PROJECT_PATHS[0], ...dynamicPaths]
    })

    return { swiperOptions, showBreadcrumbs, breadcrumbs }
  },
})
</script>

<style scoped lang="scss">
@import '/assets/scss/variables';

.breadcrumbs {
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  &__item {
    padding-right: 8px;
    width: auto;

    & span,
    & a {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
      color: $black-60;

      margin-right: 5px;
    }

    & span {
      cursor: default;
    }

    & a {
      text-decoration: none;
      transition: 0.3s;

      &:hover {
        color: $black;
      }
    }
  }
}
</style>
