import moment from 'moment'

export function convertDateToStringDMY(date, fromInput, toFormat) {
  if (date) {
    const m = moment(date, fromInput || 'DD.MM.YYYY')
    return m.format(toFormat || 'DD-MM-YYYY')
  } else {
    return null
  }
}
