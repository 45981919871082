import { defineStore } from 'pinia'

import { ProfileProvider } from '@/providers/profile.provider'
import { getYearsOfWork } from '@/utilities/installer-utility'

export const useProfileStore = defineStore('profile', {
  state: () => ({
    data: {},
    isLoading: false,
  }),
  actions: {
    async getProfileInfo(performerId) {
      this.isLoading = true

      this.data = await ProfileProvider.getProfileInfo(performerId)

      this.isLoading = false
    },
    async addPerformerToFavorite(performerId) {
      await ProfileProvider.addPerformerToFavorite(performerId)
    },
    async removePerformerToFavorite(performerId) {
      await ProfileProvider.removePerformerToFavorite(performerId)
    },
  },
  getters: {
    getCombinedMainInfo() {
      return [
        { name: 'Страна:', value: this.data.country },
        {
          name: 'Регион:',
          value: this.data.region,
        },
        { name: 'Населённый пункт:', value: this.data.city },
        {
          name: 'Скорость ответа:',
          value: this.data.averageReplyTime,
        },
        { name: 'Процент рассмторения:', value: this.data.reviewPercent ? this.data.reviewPercent : '-' },
        { name: 'Опыт работы с технологиями Рехау:', value: getYearsOfWork(this.data.experienceYears) },
      ]
    },
    getCombinedAdditionalInfo() {
      return [
        {
          title: 'О себе:',
          dataList: this.data.description,
          type: 'text',
          individualPerformerRequired: true,
        },
        { title: 'Типы объектов:', dataList: this.data.objectTypes, type: 'string' },
        { title: 'Специализации:', dataList: this.data.specializations, type: 'string' },
        { title: 'Допуски:', dataList: this.data.permissions, type: 'string' },
        {
          title: 'Дополнительные работы:',
          dataList: this.data.additionalServices,
          type: 'string',
        },
        {
          title: 'Владелец монтажной организаации:',
          dataList: this.data?.owner ? [this.data?.owner] : null,
          type: 'image',
          MORequired: true,
        },
        { title: 'Список сотрудников:', dataList: this.data.workers, type: 'image', MORequired: true },
        {
          title: 'Достижения:',
          dataList: this.data.achievements,
          type: 'achievements',
          individualPerformerRequired: true,
        },
        {
          title: 'Сертификат REHAU Pro:',
          dataList: this.data.certificateUrl ? [{ id: 1, url: this.data.certificateUrl, title: 'Сертификат' }] : null,
          type: 'icon',
        },
        { title: 'Сертификаты других организаций:', dataList: this.data.otherCertificates, type: 'icon' },
      ]
    },
  },
})
